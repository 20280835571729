::selection {
  background-color: #43f4fd4d;
}

article, footer, header, section {
  display: block;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background-color: #1d1d1d;
  font-family: Overpass Mono, Monaco, Consolas, monospace;
  font-size: 14px;
}

body {
  margin: 0;
}

a {
  color: #43f4fd;
  background: none;
}

em[itemprop="child"] {
  color: #fd41c3;
}

em[itemprop="language"] {
  color: #fbb829;
}

em[itemprop="interest"] {
  color: #5ff967;
}

em {
  color: #fff;
  font-style: normal;
}

.key-value-pair {
  opacity: 0;
}

mark {
  color: #000;
  background: #ff0;
}

code, pre {
  font-family: inherit;
  font-size: 1em;
}

*, :before, :after {
  box-sizing: border-box;
}

html, body {
  color: #b3b3b3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  line-height: 1.3;
}

body {
  overflow-x: hidden;
}

a {
  border-bottom: 1px dotted;
  text-decoration: none;
  transition: border-color .3s;
}

a:focus {
  outline-offset: 3px;
  outline: 1px solid;
}

a:hover {
  border-bottom-style: solid;
}

a.fa {
  border: 1px solid #0000;
  margin-right: 1rem;
  padding: 1rem;
  font-size: 1.5em;
  text-decoration: none;
}

a.fa:first-child {
  margin-left: -1rem;
}

a.fa:hover {
  border-color: #43f4fd;
}

h2 {
  color: #666;
  flex-basis: auto;
  margin: 0 0 2rem;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  position: relative;
}

h2:last-child {
  margin: 0;
}

h2 span {
  margin-bottom: 40px;
  display: block;
  position: relative;
}

h2 span:after {
  content: "";
  background: #43f4fd;
  width: 30px;
  height: 1px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.background {
  background: url("rick.3d4801eb.jpg") 100% / cover no-repeat;
}

.background:after {
  content: "";
  z-index: 0;
  background: linear-gradient(to right, #000000f2 0%, #000000d9 20%, #0006 40%, #0003 100%), linear-gradient(to left, #000000f2 0%, #000000d9 20%, #0006 40%, #0003 100%);
  position: absolute;
  inset: 0;
}

main {
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding-top: 3rem;
  padding-left: 75px;
  font-size: 18px;
  display: flex;
  position: relative;
  overflow: hidden;
}

main > * {
  z-index: 1;
  position: relative;
}

main footer {
  margin-top: auto;
  margin-bottom: 2rem;
}

main pre {
  max-width: 60vw;
}

main pre code {
  color: #737373;
}

main pre code a:not(:hover) {
  border: 0;
}

code {
  color: #fff;
  background: #ffffff26;
  padding: 0 .25rem;
}

pre {
  white-space: pre-line;
  margin-top: 0;
}

pre code {
  background: 0;
  padding: 0;
}

pre code .bracket {
  opacity: 0;
}

[aria-hidden] {
  display: none;
}

@media (width <= 750px) {
  .media-not-small {
    display: none;
  }

  .bracket {
    margin-left: -15px;
  }

  a {
    border: none !important;
  }

  main, main.background {
    padding: 10vw;
    font-size: 14px;
  }

  main:after, main.background:after {
    background: linear-gradient(to right, #000000e6 0%, #00000080 70%, #0000 100%);
  }

  main pre, main.background pre {
    max-width: none;
  }
}
/*# sourceMappingURL=index.3d24925f.css.map */
